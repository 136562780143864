<template>
  <b-container
    fluid
  >

    <b-row>
      <b-col
        cols="12"
      >
        <b-card
          title="Payment Vouchers"
          sub-title="Payment Request"
          class="shadow-sm"
        >
          <b-row>

            <b-col
              cols="12"
              md="2"
              class="p-2"
            >
              <PaleCard
                label="Total Vouchers"
                variant="success"
                :count="vouchers.voucher_total"
                :link="{ name: 'dh.payment-vouchers', params: { dh: 'pending' }}"
                :loading="fetching"
              />
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="p-2"
            >
              <PaleCard
                label="Canceled Vouchers"
                variant="danger"
                :count="vouchers.voucher_canceled"
                :link="{ name: 'dh.payment-vouchers', params: { dh: 'histories' }}"
                :loading="fetching"
              />
            </b-col>

          </b-row>
        </b-card>
      </b-col>
    </b-row>

  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { DHDashboardService } from '@/services'
import PaleCard from '@/components/custom/PaleCard'

export default {
  name: 'DhDashboard',

  middleware: ['auth', 'dh'],

  components: {
    PaleCard
  },

  metaInfo: () => ({
    title: 'Dashboard'
  }),

  data () {
    return {
      fetching: false,
      vouchers: {
        voucher_total: 0,
        voucher_canceled: 0
      }
    }
  },

  mounted () {
    core.index()
    this.getStatistics()
  },

  methods: {

    async getStatistics () {
      this.fetching = true
      return await DHDashboardService.get().then(({ data }) => {
        this.vouchers.voucher_total = data.voucher_total
        this.vouchers.voucher_canceled = data.voucher_canceled
        this.fetching = false
      })
    }

  }
}
</script>
